import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import { useProgressOverlayContext } from "client/src/domain/EIF/QPSPushProgressOverlay/QPSPushProgressOverlay";
import { invalidateDEIFChanges } from "client/src/hooks/changeLogs";
import { compareQueryKey, useSlobMutation, useSlobQuery } from "client/src/hooks/query";
import { socketClient } from "client/src/hooks/useWebSocket";
import { useEffect } from "react";
import type { ProgressOverlayStatus } from "client/src/domain/EIF/QPSPushProgressOverlay/QPSPushProgressOverlay";
import type { JsonToTypeMapper, ResponseError } from "client/src/hooks/query";
import type { APIJobStatus } from "shared/types/APIJob";
import type { ClientId } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type {
  CreateEmployeeClassFromQPSInput,
  MapAndSyncQPSClassesInput,
  QPSClass,
  QPSPlanDesignInfo,
} from "shared/types/QPSClass";
import type { QPSPushStatusEvent } from "shared/types/Socket";

const jsonQPSPlanDesignInfoToQPSPlanDesignInfo: JsonToTypeMapper<QPSPlanDesignInfo> = (
  planDesignInfo,
) => planDesignInfo;

export const jsonQPSClassToQPSClass: JsonToTypeMapper<QPSClass> = (qpsClass) => {
  return {
    ...qpsClass,
    createdAt: new Date(qpsClass.createdAt),
    updatedAt: new Date(qpsClass.updatedAt),
    deletedAt: qpsClass.deletedAt ? new Date(qpsClass.deletedAt) : null,
  };
};

export function useSyncWithQPS() {
  const query = useSlobMutation<void, void, `/api/clients/:clientId/sync-qps`>({
    method: "post",
    path: `/api/clients/:clientId/sync-qps`,
  });
  return query;
}

export type GetQPSPlanDesignInfo = ReturnType<typeof useGetQPSPlanDesignInfo>["refetch"];

export function useGetQPSPlanDesignInfo(clientId: ClientId, { enabled }: { enabled: boolean }) {
  return useSlobQuery<QPSPlanDesignInfo>({
    method: "get",
    path: `/api/clients/${clientId}/qps/plan-design-info`,
    map: (response) => jsonQPSPlanDesignInfoToQPSPlanDesignInfo(response),
    options: {
      placeholderData: keepPreviousData,
      enabled,
      refetchOnWindowFocus: false,
    },
  });
}

export type CreateEmployeeClassFromQPSFunc = ReturnType<
  typeof useCreateEmployeeClassFromQPSBasicClasses
>["mutateAsync"];

export function useCreateEmployeeClassFromQPSBasicClasses() {
  const queryClient = useQueryClient();

  const query = useSlobMutation<
    CreateEmployeeClassFromQPSInput,
    EmployeeClass,
    `/api/clients/:clientId/qps/employee-classes`,
    ResponseError,
    unknown
  >({
    method: "post",
    path: `/api/clients/:clientId/qps/employee-classes`,
    options: {
      async onSuccess(_, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/employee-classes`]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });
  return query;
}

export type MapAndSyncQPSClassesFunc = ReturnType<typeof useMapAndSyncQPSClasses>["mutateAsync"];

export function useMapAndSyncQPSClasses() {
  const queryClient = useQueryClient();

  const query = useSlobMutation<
    MapAndSyncQPSClassesInput,
    void,
    `/api/clients/:clientId/qps/map-&-sync`
  >({
    method: "post",
    path: `/api/clients/:clientId/qps/map-&-sync`,
    options: {
      async onSuccess(_, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/map-&-sync`]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });
  return query;
}

export type AsyncQPSPushFunc = ReturnType<typeof useAsyncQPSPush>["mutateAsync"];

export function useAsyncQPSPush() {
  const queryClient = useQueryClient();

  const query = useSlobMutation<
    MapAndSyncQPSClassesInput,
    void,
    `/api/clients/:clientId/qps/async-push`
  >({
    method: "post",
    path: `/api/clients/:clientId/qps/async-push`,
    options: {
      async onSuccess(_, { params: { clientId } }) {
        // required to get the API Job with "in progress" status
        await queryClient.invalidateQueries({
          queryKey: ["get", `/api/clients/${clientId}`],
        });
      },
    },
  });
  return query;
}

const apiJobStatusToOverlayStatus: Record<APIJobStatus, ProgressOverlayStatus> = {
  NOT_APPLICABLE: "success",
  IN_PROGRESS: "loading",
  COMPLETED: "success",
  COMPLETED_WITH_WARNINGS: "success-with-warnings",
  COMPLETED_WITH_ERRORS: "error",
};

export function useWebSocketQPSPushStatusEvent() {
  const queryClient = useQueryClient();
  const qpsProgressContext = useProgressOverlayContext();

  useEffect(() => {
    socketClient.on("QPS_PUSH_STATUS", async ({ clientId, apiJobStatus }: QPSPushStatusEvent) => {
      // Invalidate the cache of the client to update the status with the new data.
      if (apiJobStatus !== "IN_PROGRESS") {
        await queryClient.invalidateQueries({
          queryKey: ["get", `/api/clients/${clientId}`],
        });
      }

      qpsProgressContext.updateStatus(clientId, apiJobStatusToOverlayStatus[apiJobStatus]);
    });

    return () => {
      socketClient.off("QPS_PUSH_STATUS");
    };
  }, [queryClient, qpsProgressContext]);
}
