import { Select } from "antd";
import { InputErrorMessage } from "client/src/components/Form/InputErrorMessage";
import { Body2 } from "client/src/components/Typography/Typography";
import clsx from "clsx";
import { useCallback, useId, useState } from "react";

import * as styles from "./SlobSelect.module.less";
import * as labelStyles from "./form-labels.module.less";

import type { LabeledValue, SelectProps } from "antd/es/select";
import type { CommonFormInputsProps } from "client/src/components/Form/formTypes";

export type SlobDefaultOptionType =
  | {
      key?: string;
      label: React.ReactNode;
      value: string | number;
    }
  | {
      key?: string;
      label: React.ReactNode;
      options: SlobDefaultOptionType[];
    };

export interface SlobSelectProps<T extends LabeledValue>
  extends Omit<CommonFormInputsProps, "name" | "label"> {
  id?: string;
  value: T["value"] | null | undefined;
  onChange: SelectProps<T>["onChange"];
  onClear?: () => void;
  options: T[] | SlobDefaultOptionType[];
  open?: boolean;
  onInputKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  label?: React.ReactNode;
  placeholder: string;
  loading?: boolean;
  allowClear?: boolean;
  fillWidth?: boolean;
  dropdownRender?: SelectProps<T>["dropdownRender"];
  optionLabelProp?: SelectProps<T>["optionLabelProp"];
  onSearch?: SelectProps<T>["onSearch"];
  "data-testid"?: string;
}

export const SlobSelect = <T extends LabeledValue>({
  id: providedId,
  value,
  onFocus,
  onBlur,
  onChange: onChangeOption,
  onClear,
  onSearch,
  open,
  error,
  showRequired,
  options,
  onInputKeyDown,
  disabled = false,
  loading = false,
  allowClear = false,
  touched = false,
  label,
  placeholder,
  fillWidth = false,
  dropdownRender,
  optionLabelProp,
  "aria-label": ariaLabel,
  "data-testid": dataTestId,
}: SlobSelectProps<T>) => {
  const [isFocused, setIsFocused] = useState(false);

  const labelClass = clsx(
    styles.dropdownLabel,
    labelStyles.formLabel,
    { [labelStyles.formLabelOver]: !!value || isFocused },
    { [labelStyles.formLabelDisabled]: disabled },
  );
  const containerClass = clsx(
    styles.fieldContainer,
    disabled && styles.fieldContainerDisabled,
    loading && styles.fieldContainerLoading,
  );

  const computedId = useId();
  const id = providedId || computedId;
  const placeholderSrOnly = (
    <span className="sr-only">{placeholder + (showRequired ? " (required)" : "")}</span>
  );

  const shouldShowError = touched && Boolean(error);
  const errorId = shouldShowError ? `${id}__errormessage` : undefined;
  const inputClass = clsx(styles.select, {
    [styles.dropdownError]: shouldShowError,
  });

  const onInputFocus = useCallback<Exclude<typeof onFocus, undefined>>(
    (e) => {
      setIsFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus],
  );

  const onInputBlur = useCallback<Exclude<typeof onBlur, undefined>>(
    (e) => {
      setIsFocused(false);
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur],
  );

  const onChangeValue: SelectProps<T["value"]>["onChange"] = (value, option) => {
    const predicate = (item: SlobDefaultOptionType): T | T[] =>
      "options" in item
        ? item.options.flatMap(predicate)
        : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
          (item as T);

    const flattenedOptions = options.flatMap(predicate);
    const selectedOption = flattenedOptions.find((option) => option.value === value);
    if (selectedOption) {
      if (selectedOption.key === undefined) {
        selectedOption.key = value.toString();
      }
      onChangeOption?.(selectedOption, option);
    }
  };

  return (
    <div data-component="SlobSelect">
      {typeof label === "string" ? (
        <p>
          <Body2 as="label" htmlFor={id}>
            {label}
            {placeholderSrOnly}
          </Body2>
        </p>
      ) : label == null ? (
        <label htmlFor={id}>{placeholderSrOnly}</label>
      ) : (
        label
      )}

      <div className={containerClass}>
        <span className={labelClass} aria-hidden={true}>
          {placeholder + (showRequired ? "*" : "")}
        </span>

        <Select<T["value"]>
          id={id}
          allowClear={allowClear}
          onClear={onClear}
          open={open}
          showSearch={true}
          onSearch={onSearch}
          labelInValue={false}
          suffixIcon={null}
          className={clsx("ant-select-customize-input", inputClass)}
          popupClassName={styles.selectPopup}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onChange={onChangeValue}
          onInputKeyDown={onInputKeyDown}
          options={options}
          disabled={disabled}
          value={value}
          filterOption
          optionFilterProp="label"
          optionLabelProp={optionLabelProp}
          style={{ width: fillWidth ? "100%" : undefined }}
          dropdownRender={dropdownRender}
          aria-invalid={shouldShowError}
          aria-errormessage={errorId}
          aria-label={ariaLabel}
          ref={
            dataTestId
              ? (node) => {
                  const input = node?.nativeElement.querySelector("input");
                  input?.setAttribute("data-testid", dataTestId);
                }
              : undefined
          }
        />

        <div aria-live="assertive">
          {shouldShowError && <InputErrorMessage id={errorId} error={error} />}
        </div>
      </div>
    </div>
  );
};
