import {
  getIsClaimsCheckBenefitType,
  type BenefitTypeEIF,
  type PFMLASOBenefitType,
  type PFMLBenefitType,
  getIsPFMLASOBenefitType,
  getIsPFMLFullyInsuredBenefitType,
  getIsPFMLBenefitType,
  getIsStatutoryBenefitType,
} from "shared/types/BenefitTypes";
import {
  benefitTypeToCoverage,
  type PFMLASOCoverage,
  type SlfCoverageLongName,
} from "shared/types/SlfCoverages";
import { rejectNullableValues } from "shared/utils/utils";
import type {
  SendMonthlyClaimsReportsTo,
  SendClaimsCheckTo,
  ClaimsCheckPayee,
} from "@prisma/client";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { ClientId } from "shared/types/Client";
import type { Contact, ContactId } from "shared/types/Contact";
import type { EmployerPremiumPayments } from "shared/types/EmployeeClassPlan";
import type { Pretty, ValueOf } from "shared/types/Helper";
import type { QPSClass } from "shared/types/QPSClass";
import type { UserId } from "shared/types/User";

export type PlanId = string;
export type Level = "HIGH" | "LOW" | "NOT_APPLICABLE";
export type TerminationDate = "DATE_OF_TERM" | "END_OF_MONTH";

export const sendMonthlyClaimsReportTo: SendMonthlyClaimsReportsTo[] = [
  "PRIMARY_PLAN_ADMINISTRATOR",
  "OTHER",
];
export const sendClaimsCheckTo: SendClaimsCheckTo[] = [
  "PRIMARY_PLAN_ADMINISTRATOR",
  "EMPLOYEE_HOME",
  "EMPLOYER",
];
export const claimsCheckPayee: ClaimsCheckPayee[] = ["PLAN_ADMINISTRATOR", "SOMEONE_ELSE"];

export type Plan<T extends BenefitTypeEIF = BenefitTypeEIF> = {
  id: PlanId;
  clientId: ClientId;
  benefitType: T;
  level: Level;
  terminationDate: TerminationDate | null;
  salaryBased: boolean | null;
  pfmlOption: PFMLOptions | null;
  payeeListedOnTheCheckContactId: ContactId | null;
  payeeListedOnTheCheckContact: Contact | null;
  sendClaimsCheckTo: SendClaimsCheckTo | null;
  claimsCheckPayee: ClaimsCheckPayee | null;
  planAdminPayeeContactId: ContactId | null;
  someoneElsePayeeContactId: ContactId | null;
  pfmlPremiumContributionType: PFMLPremiumContributionType | null;
  pfmlEmployerContributionPercentage: number | null;
  pfmlEmployerPremiumPayments: EmployerPremiumPayments | null;
  pfmlThreeYearLookBackPercent: number | null;
  qpsClasses: QPSClass[];

  createdAt: Date;
  createdBy: UserId;
  updatedAt: Date;
  updatedBy: UserId;
  deletedAt: Date | null;
  deletedBy: UserId | null;
};

export type CreatePlanInput = Pick<Plan, "clientId" | "benefitType" | "level">;

export type BulkAddRemovePlanInput = {
  plansToAdd: CreatePlanInput[];
  plansToRemove: PlanId[];
};

export type UpdatePlanInput = Pretty<
  Pick<Plan, "id"> &
    Partial<
      Pick<
        Plan,
        | "terminationDate"
        | "payeeListedOnTheCheckContactId"
        | "salaryBased"
        | "pfmlOption"
        | "sendClaimsCheckTo"
        | "claimsCheckPayee"
        | "planAdminPayeeContactId"
        | "someoneElsePayeeContactId"
        | "pfmlPremiumContributionType"
        | "pfmlEmployerContributionPercentage"
        | "pfmlEmployerPremiumPayments"
        | "pfmlThreeYearLookBackPercent"
      >
    >
>;

export const pfmlStates = [
  "Colorado",
  "Connecticut",
  "Massachusetts",
  "Oregon",
  "Washington",
] as const;
export type PFMLStates = ValueOf<typeof pfmlStates>;

export const pfmlASOCoverageToStateLabel: Record<PFMLASOCoverage, PFMLStates> = {
  "ASO CT PFML": "Connecticut",
  "ASO MA PFML": "Massachusetts",
  "ASO WA PFML": "Washington",
  "ASO CO PFML": "Colorado",
  "ASO OR PFML": "Oregon",
} as const;

export const pfmlASOBenefitTypeToStateLabel: Record<PFMLASOBenefitType, PFMLStates> = {
  PFML_CO_ASO: "Colorado",
  PFML_CT_ASO: "Connecticut",
  PFML_MA_ASO: "Massachusetts",
  PFML_OR_ASO: "Oregon",
  PFML_WA_ASO: "Washington",
};

export const pfmlOptionsLabels = [
  "Advice only",
  "Advice with benefit calc",
  "Advice plus",
] as const;
export const pfmlOptions = ["ADVICE_ONLY", "ADVICE_WITH_BENEFIT_CALC", "ADVICE_PLUS"] as const;

export type PFMLOptionsLabels = ValueOf<typeof pfmlOptionsLabels>;
export type PFMLOptions = ValueOf<typeof pfmlOptions>;

export const pfmlOptionsRecord: Record<PFMLOptions, PFMLOptionsLabels> = {
  ADVICE_ONLY: "Advice only",
  ADVICE_WITH_BENEFIT_CALC: "Advice with benefit calc",
  ADVICE_PLUS: "Advice plus",
};

export const pfmlOptionPerks = [
  "CONTRIBUTIONS",
  "CLAIM_CHECK_MAILING_LOCATIONS",
  "MONTHLY_CLAIMS_REPORTS_AND_EOBS",
] as const;
export type PfmlOptionPerks = ValueOf<typeof pfmlOptionPerks>;

export const pfmlOptionPerksMap: Record<PFMLOptions, PfmlOptionPerks[]> = {
  ADVICE_ONLY: [],
  ADVICE_WITH_BENEFIT_CALC: ["CONTRIBUTIONS"],
  ADVICE_PLUS: [
    "CONTRIBUTIONS",
    "CLAIM_CHECK_MAILING_LOCATIONS",
    "MONTHLY_CLAIMS_REPORTS_AND_EOBS",
  ],
};

export const pfmlPremiumContributionTypes = ["FULLY_EMPLOYER_PAID", "GROSS_UP", "SHARED"] as const;
export type PFMLPremiumContributionType = ValueOf<typeof pfmlPremiumContributionTypes>;

export const pfmlBenefitTypeToRelevantStateLabel: Record<PFMLBenefitType, PFMLStates> = {
  PFML_CO_ASO: "Colorado",
  PFML_CO_FULLY_INS: "Colorado",
  PFML_CT_ASO: "Connecticut",
  PFML_CT_FULLY_INS: "Connecticut",
  PFML_MA_ASO: "Massachusetts",
  PFML_MA_FULLY_INS: "Massachusetts",
  PFML_OR_ASO: "Oregon",
  PFML_OR_FULLY_INS: "Oregon",
  PFML_WA_ASO: "Washington",
} as const;

export type PFMLContributionSections = "PFML_CO" | "PFML_CT" | "PFML_MA" | "PFML_OR" | "PFML_WA";
export type StatutoryContributionSections = "DBL" | "TDI" | "PFL" | "TDB";

export type StatutoryContributionLabels = Extract<
  SlfCoverageLongName,
  | "New York Disability"
  | "Hawaii Temporary Disability"
  | "New York Paid Family Leave"
  | "New Jersey Temporary Disability"
>;

export const StatutoryBenefitTypeToCoverage: Record<
  StatutoryContributionSections,
  StatutoryContributionLabels
> = {
  DBL: "New York Disability",
  TDI: "Hawaii Temporary Disability",
  PFL: "New York Paid Family Leave",
  TDB: "New Jersey Temporary Disability",
};

// We cannot simply use the BenefitTypes for this because in the context of contribution,
// each PFML state should only be represented once, even if there are multi PFML benefitTypes for a state
export type PFMLStatutoryContributionSections =
  | PFMLContributionSections
  | StatutoryContributionSections;

export const pfmlContributionSections: PFMLStatutoryContributionSections[] = [
  "PFML_CO",
  "PFML_CT",
  "PFML_MA",
  "PFML_OR",
  "PFML_WA",
];

export const statutoryContributionSection = ["DBL", "TDI", "PFL", "TDB"];

export const pfmlStateToContributionSection: Record<PFMLStates, PFMLStatutoryContributionSections> =
  {
    Colorado: "PFML_CO",
    Connecticut: "PFML_CT",
    Massachusetts: "PFML_MA",
    Oregon: "PFML_OR",
    Washington: "PFML_WA",
  } as const;

export const pfmlStateToRelevantBenefitTypesMap: Record<PFMLStates, BenefitTypeEIF[]> = {
  Colorado: ["PFML_CO_ASO", "PFML_CO_FULLY_INS"],
  Connecticut: ["PFML_CT_ASO", "PFML_CT_FULLY_INS"],
  Massachusetts: ["PFML_MA_ASO", "PFML_MA_FULLY_INS"],
  Oregon: ["PFML_OR_ASO", "PFML_OR_FULLY_INS"],
  Washington: ["PFML_WA_ASO"],
};

export type PFMLContributions = Pick<
  Plan,
  | "pfmlPremiumContributionType"
  | "pfmlEmployerContributionPercentage"
  | "pfmlEmployerPremiumPayments"
  | "pfmlThreeYearLookBackPercent"
>;

export const pfmlEmployerPremiumPaymentsLabels = [
  "Pre-tax",
  "Post-tax",
  "Tax Choice (Pre-tax or Post-tax)",
] as const;
export type PFMLEmployerPremiumPaymentsLabel = ValueOf<typeof pfmlEmployerPremiumPaymentsLabels>;
export const pfmlEmployerPremiumPayments = ["PRE_TAX", "POST_TAX", "TAX_CHOICE"] as const;
export type PFMLEmployerPremiumPayments = ValueOf<typeof pfmlEmployerPremiumPayments>;
export const pfmlEmployerPremiumPaymentsValuesRecord: Record<
  PFMLEmployerPremiumPayments,
  PFMLEmployerPremiumPaymentsLabel
> = {
  PRE_TAX: "Pre-tax",
  POST_TAX: "Post-tax",
  TAX_CHOICE: "Tax Choice (Pre-tax or Post-tax)",
};

export function getPlansWhereCanSetClaimsCheckLocations(plans: Plan[]) {
  return plans.reduce<Plan[]>((acc, plan) => {
    if (getIsClaimsCheckBenefitType(plan.benefitType)) {
      if (getIsPFMLFullyInsuredBenefitType(plan.benefitType)) {
        acc.push(plan);
      } else if (getIsPFMLASOBenefitType(plan.benefitType)) {
        if (plan.pfmlOption === "ADVICE_PLUS") {
          acc.push(plan);
        }
      } else {
        acc.push(plan);
      }
    }
    return acc;
  }, []);
}

export function getClientCoveragesThatAreAlwaysSentToEmployeesHome(plans: Plan[]) {
  const coveragesThatAreAlwaysSentToEmployeesHome: BenefitTypeEIF[] = [
    "LONG_TERM_DISABILITY",
    "FLI",
    "TDI",
    "SUNADVISOR_ADVICE_PLUS",
  ];

  const clientCoveragesThatAreAlwaysSentToEmployeesHome = plans
    .filter((plan) => coveragesThatAreAlwaysSentToEmployeesHome.includes(plan.benefitType))
    .map((plan) => benefitTypeToCoverage[plan.benefitType])
    .sort();

  return clientCoveragesThatAreAlwaysSentToEmployeesHome;
}

export function getClaimsCheckMailingLocationKey(plan: Plan) {
  return `${plan.sendClaimsCheckTo}:${plan.claimsCheckPayee}:${plan.planAdminPayeeContactId}:${plan.someoneElsePayeeContactId}:`;
}

export type ShowPlanComparisonFields = "MEDICAL" | "DENTAL" | "VISION" | false;

const nonClassPlanFields: Partial<keyof Plan>[] = [
  "pfmlPremiumContributionType",
  "pfmlEmployerContributionPercentage",
  "pfmlEmployerPremiumPayments",
  "pfmlThreeYearLookBackPercent",
];

export const relevantChangesForNonClassPlans = (
  plans: Plan[],
  changeSnapshot: DEIFChangeSnapshot,
) => {
  const relevantPlans = plans.filter(
    (plan) => getIsPFMLBenefitType(plan.benefitType) || getIsStatutoryBenefitType(plan.benefitType),
  );

  const relevantPlanIds = relevantPlans.map((plan) => plan.id);

  const planAdminChangeDetailInfoList = relevantPlanIds
    .flatMap((pid) => {
      if (!changeSnapshot.Plan[pid]) return [];

      const changeDetailRecords = nonClassPlanFields.map((fieldName) => {
        const changeDetailRecord = changeSnapshot.Plan[pid]?.[fieldName];
        return changeDetailRecord;
      });
      return changeDetailRecords;
    })
    .filter(rejectNullableValues);

  return planAdminChangeDetailInfoList;
};

export const terminationDateToLabel: Record<TerminationDate, string> = {
  DATE_OF_TERM: "Date of termination",
  END_OF_MONTH: "End of the month",
};
